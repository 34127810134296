import React from 'react';
import {Button, Col, Row, Container, Dropdown} from 'react-bootstrap';

// Footer - should have copyright

function Footer({links}) {
    const left_links = [links[0], links[1], links[2], links[3]];
    const right_links = [links[4],links[5]];
    return(
        <div className="footer">
        <Container>
            <Row>
                <Col>
                    {left_links.map((link) => (
                        <Row key={link.name} className='mb-2'>
                            <a className='footer_links' href={link.ref} target={link?.target} rel='nofollow'>{link.name}</a>
                        </Row>
                    ))}
                    <Row className='mb-2'>
                        <Dropdown id='footer_dropdown'>
                            <Dropdown.Toggle variant="link" className='footer_links footer_dropdown_item'>
                                Contact Us
                            </Dropdown.Toggle>
    
                            <Dropdown.Menu>
                                <Dropdown.Item href='https://sicorp.atlassian.net/servicedesk/customer/portal/40/group/91/create/292' target='_blank' rel='nofollow'>Become a Vendor</Dropdown.Item>
                                <Dropdown.Item href='https://sicorp.atlassian.net/servicedesk/customer/portal/40/group/91/create/293' target='_blank' rel='nofollow'>Become a Customer</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Row>
                
                    {right_links.map((link) => (
                        <Row key={link.name} className='mb-2'>
                            <a className='footer_links' href={link.ref} target={link?.target} rel='nofollow'>{link.name}</a>
                        </Row>
                    ))}
                </Col>
                <Col className='text-center'>
                    <p>Copyright © 2024 SICORP. All Rights Reserved.</p>
                </Col>
                <Col>
                    <Button href="#top" variant='secondary' style={{float: 'right'}}>
                        ^
                    </Button>
                </Col>
            </Row>
            </Container>
        </div>
    );
};

export default Footer;