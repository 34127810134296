import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
// 404 Page
function NotFoundPage() {
    return(
        <>
            <Row className="justify-content-center">
                <Col className="grey_text_boxes">
                    <h3>404 - Not Found</h3>
                    <p>Sorry, this page does not exist.</p>
                    <Col>
                        <Button href='/' variant='info' className="me-3">Home</Button>
                        <Button variant='outline-info' onClick={()=>{window.history.back()}}>Go back</Button>
                    </Col>
                </Col>
            </Row>
        </>
    );
};
export default NotFoundPage;