import React, {useEffect, useState} from "react";
import {Row, Col, Button, Spinner} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import NotFoundPage from "./404.js";

function Job (){
    const {id} = useParams(); // gets id number from url

    // eslint-disable-next-line
    const [job, setJob] = useState((!window.sessionStorage.getItem('offerings')) ? '' : JSON.parse(window.sessionStorage.getItem('offerings'))[id-1]); // sets job to sopecific element of offerings data
    
    // variables to use during structured data setup
    var structure ={};
    var sal = '';
    var date ='';
    var datePost = '';

    // used for visual loading
    const [loading, setLoading] = useState(false);
    
    // on first page load, sets the job offerings if there are none
    useEffect(() => {
        // eslint-disable-next-line
        if(!window.sessionStorage.getItem('offerings')){
            get_jobs();
        }
        // eslint-disable-next-line
    }, []);

    const get_jobs = async() => {
        // start visual loading
        setLoading(true);
        try {
            const response = await fetch('/api/get_jobs');
            if (!response.ok) {
                // error handling
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            else{
                // if everything goes well, sets the job data
                const data = await response.json();
                if(data[id-1]){
                    // if the job exists
                    setJob(data[id-1]);
                }
                else{
                    // if the job does not exist, set to empty
                    setJob('');
                }
                setLoading(false);

                // sets local storage so that other pages can access
                window.sessionStorage.setItem("offerings", JSON.stringify(data));
            }
        } catch (error) {
            // stop loading
            console.error(error);
            setLoading(false);
        }
    }

    // checks if salary or hourly fields are set (used for specific structured data for google)
    if(job && job?.Title && job?.SalaryMin){
        sal = 'YEAR'
    }
    else{
        sal = "HOUR"
    }

    // sets date formatting matching YYYY-MM-DD
    if(job && job?.Title && job?.Expiration){
        const mm = job?.Expiration.slice(0,2);
        const dd = job?.Expiration.slice(3,5);
        const yyyy = job?.Expiration.slice(-4);
        date = yyyy + '-' + mm + '-' + dd;
    }

    if(job && job?.Title && job?.DatePosted){
        const mm = job?.DatePosted.slice(0,2);
        const dd = job?.DatePosted.slice(3,5);
        const yyyy = job?.DatePosted.slice(-4);
        datePost = yyyy + '-' + mm + '-' + dd;
    }
    
    // defines structured data following google guidelines
    // eslint-disable-next-line
    if(job && job?.Title && job?.RemoteWork == 'Remote'){
        // if the job is remote, add the telecommute data
        structure = {
            "@context" : "https://www.schema.org/", // required
            "@type" : "JobPosting", // required
            "title" : job?.Title, // required
            "datePosted" : datePost, // required
            "description": `<p>${job?.Details}</p>`, // required
            "validThrough" : date,
            "hiringOrganization" : { // required
                "@type" : "Organization",
                "name" : "SICORP, Inc",
                "sameAs" : "https://www.sicorp.com",
                "logo" : "https://www.sicorp.jobs/media/BUG.png"
            },
            "applicantLocationRequirements": {
                "@type": "Country",
                "name": "USA"
            },
            "jobLocationType": "TELECOMMUTE",
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "minValue": job?.SalaryMin || job?.HourlyMin,
                    "maxValue": job?.SalaryMax || job?.HourlyMax,
                    "unitText": sal
                }
            },
            "employmentType": (job?.Type ? (job?.Type.replace('-','_')).toUpperCase() : '') //changes data formatting from filemaker
        }
    }
    else if (job && job?.Title && job?.RemoteWork != 'Remote'){
        // if it is not remote, sets address data
        structure = {
            "@context" : "https://www.schema.org/", // required
            "@type" : "JobPosting", // required
            "title" : job?.Title, // required
            "datePosted" : datePost, // required
            "description": `<p>${job?.Details}</p>`, // required
            "validThrough" : date,
            "hiringOrganization" : { // required
                "@type" : "Organization",
                "name" : "SICORP, Inc",
                "sameAs" : "https://www.sicorp.com",
                "logo" : "https://www.sicorp.jobs/media/BUG.png"
            },
            "jobLocation": { // required
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": job?.StreetAddress,
                    "addressLocality": job?.PositionCity,
                    "addressRegion": "NM",
                    "postalCode": job?.ZipCode,
                    "addressCountry": "US"
                }
            },
            "applicantLocationRequirements": {
                "@type": "Country",
                "name": "USA"
            },
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "minValue": job?.SalaryMin || job?.HourlyMin,
                    "maxValue": job?.SalaryMax || job?.HourlyMax,
                    "unitText": sal
                }
            },
            "employmentType": (job?.Type ? (job?.Type.replace('-','_')).toUpperCase() : '')
        }
    }

    return(
        <>
            {/* Used for visual loading when the call takes too long */}
            {loading ? 
            <Row className='justify-content-center'>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row> : null}

            {/* Checks if the job is defined, if not, show 404 page */}
            {/* eslint-disable-next-line */}
            {(!job || job =='' || !job?.Title) ? <NotFoundPage/> :
            <>
            {/* Include structured data in script element */}
            <script type='application/ld+json'>
                {JSON.stringify(structure)}
            </script>

            {/* Buttons for navigating to each individual job posting */}
            <Row className='justify-content-center text-center mb-3'>
                {(id-1 > 0) ?
                JSON.parse(window.sessionStorage.getItem('offerings'))[id-2]?.Title ? (
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)-1}`}>{'<'} Previous Posting</Button>
                </Col>) :
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)-2}`}>{'<'} Previous Posting</Button>
                </Col>
                : null}
                {(id < JSON.parse(window.sessionStorage.getItem('offerings')).length) ?
                JSON.parse(window.sessionStorage.getItem('offerings'))[id]?.Title ? (
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)+1}`}>Next Posting {'>'}</Button>
                </Col>) :
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)+2}`}>{'>'} Next Posting</Button>
                </Col>
                : null}
            </Row>
            
            {/* Individual job posting visual */}
            <Row className="px-5 pb-5" key={job?.PID}>
                <h3>{job?.PID} - {job?.Title}
                <Button className='ms-5' variant='info' size='lg' href='https://sicorp.atlassian.net/servicedesk/customer/portal/42/group/103/create/10145'>
                    Apply Here!
                </Button></h3>
                <b>Job Description:</b>
                <p style={{whiteSpace:'pre-wrap'}}>{job?.Description}</p>
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Employment Type</p>
                        <p>{job?.Type}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Wage Type</p>
                        <p>{job?.WageType}</p>
                    </Col>
                </Row>
                {/* Only show the salary or hourly field depending on what is defined */}
                {/* eslint-disable-next-line */}
                {job?.fieldData?.WageType == 'Salary' ?
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Minimum Salary</p>
                        <p>${job?.SalaryMin}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Maximum Salary</p>
                        <p>${job?.SalaryMax}</p>
                    </Col>
                </Row> :
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Hourly Minimum</p>
                        <p>${job?.HourlyMin}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Hourly Maximum</p>
                        <p>${job?.HourlyMax}</p>
                    </Col>
                </Row> }
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Location</p>
                        <p>{job?.RemoteWork}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Clearance Type</p>
                        <p>{job?.ClearanceType}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Position City</p>
                        <p>{job?.PositionCity}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Job Details</p>
                        <p style={{whiteSpace:'pre-wrap'}}>{job?.Details}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><b>Expiration Date: {job?.Expiration}</b></p>
                    </Col>
                </Row>
            </Row>
            </>}
        </>
    )
}

export default Job;