import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Modal, Spinner} from 'react-bootstrap';

// Employment form, will be exported to sicorp filemaker

function BasicApp() {
    // eslint-disable-next-line
    const initial_pos = (!window.localStorage.getItem('offerings')) ? [] : JSON.parse(window.localStorage.getItem('offerings')); // getting job offering data from local storage

    const offerings = initial_pos;

    // eslint-disable-next-line
    const initial = (offerings == '') ? 'Other' : offerings[0].fieldData.PostingID; // used to set initial dropdown for position

    // used for visual loadng
    const [loading, setLoading] = useState();

    // defining fields to be filled out
    const [formData, setFormData] = useState({
        position: initial,
        pos_specify:'',
        fname:'',
        lname:'',
        mi:'',
        email:'',
        citizen:'No',
        drug_screen:'No',
        signed_date:'',
        signature:'',
    });

    // size limit of resume
    const fileLimit = 500000;
    const [file, setFile] = useState();

    // sets resume in the field
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
      };

    // checking form validation
    const [validated, setValidated] = useState(false);

    // checks if the form has already been submitted
    const initial_val = window.sessionStorage.getItem('submitted');
    const [submitted, setSubmitted] = useState(initial_val);

    // sets the field data to the variable when a change is made
    function handleChange(event) {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    // function used to check if everything is signed and validated
    function handleSubmit(e) {
        e.preventDefault();
        const resumeDiv = document.getElementById("resume_err");
        const signDiv = document.getElementById("sign");
        if(!signed){
            signDiv.innerHTML = "You must include your signature above before you can submit.";
            return false;
        }
        else if(!check_format()){
            return false;
        }
        else{
            signDiv.innerHTML = '';
            // eslint-disable-next-line
            if(file == undefined){
                resumeDiv.innerHTML = 'No file selected';
            }
            else if (file.size > fileLimit){
                resumeDiv.innerHTML = 'File too large. Must be less than 500KB';
            }
            // eslint-disable-next-line
            else if(file.type != 'application/pdf'){
                resumeDiv.innerHTML = 'Incorrect file format. Must be a pdf file';
            }
            else{
                resumeDiv.innerHTML = '';
                send_data(); // sends application if everything goes well
            }
        }
    };

    //  makes sure only letters and numbers are included in the form (no special characters)
    function check_format(){
        const keys = Object.keys(formData);
        const regex = /^[a-zA-Z0-9 ./-]*$/; // created by chatgpt
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // created by chatgpt
        const signDiv = document.getElementById("sign");
        for(let i=0; i<keys.length; i++){
            if(regex.test(formData[keys[i]])){
                continue;
            }
            // eslint-disable-next-line
            else if(keys[i] == 'email' && emailRegex.test(formData[keys[i]])){
                continue
            }
            else{
                signDiv.innerHTML = `Incorrect data formatting. Please change the entry in the following field: ${keys[i]}. You entered ${formData[keys[i]]} which is not allowed.`;
                return false;
            }
        }
        return true;
    }

    // uploads application data to backend
    const send_data = async() => {
        setLoading(true);

        // used for filemaker formatting
        const form = new FormData();
        form.append('data', JSON.stringify(formData));
        form.append('upload', file);

        // handling error messages
        const errorDiv = document.getElementById("overall_err");
        const sendErr = document.getElementById("send_err");
        try {
            // call to backend
            const response = await fetch('/api/upload', {
                method:'POST',
                body: form,
            });
            if (!response.ok) { // gets an error from backend
                const whole = await response.json();
                // eslint-disable-next-line
                if(whole.messages != 'Could not send application'){
                    errorDiv.innerHTML = whole.messages;
                }
                else{
                    errorDiv.innerHTML = "";
                }
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            else{
                // if everything goes well, notifies the user
                errorDiv.innerHTML = "";
                sendErr.innerHTML = "";
                setLoading(false);
                setValidated(true);
                setSubmitted('true'); // change to different screen when successful submit
            }
        } catch (error){
            // error handling
            // console.log('Fetch error:', error); // for testing
            sendErr.innerHTML = "There was a problem submitting your response. Please try again later.";
            setLoading(false);
            setSubmitted('false');
        }
    }

    // handling the signature
    const [signed, setSigned] = useState(false);
    const [show, setShow] = useState(false);
            
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // used to validate the date formatting
    const date_regex = /^([1-9]|1[0-2]|0[1-9])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/; // created by chatgpt

    // used to validate the email formatting
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // created by chatgpt

    // makes sure the user has signed their application
    function check_signed(){
        const dateDiv = document.getElementById("date_error");
        const signDiv = document.getElementById("sign_error");
        if(!date_regex.test(formData.signed_date)){
            dateDiv.innerHTML = "Incorrect date format (MM/DD/YYYY)";
            return false;
        }
        else{
            dateDiv.innerHTML = '';
        }
        // eslint-disable-next-line
        if(formData.signed_date=='' || formData.signature==''){
            signDiv.innerHTML = "You must fill out both fields";
            return false;
        }
        else{
            signDiv.innerHTML = '';
        }
        const sign_err = document.getElementById("sign");
        sign_err.innerHTML = '';
        setSigned(true);
        handleClose();
    }

    // on first page load, sets submitted to its previous value
    useEffect(() => {
        setSubmitted(window.sessionStorage.getItem('submitted'));
    }, []);

    // sets submitted value whenever it is changed
    useEffect(() => {
        window.sessionStorage.setItem('submitted', submitted);
    }, [submitted]);

    return(
        <div>
{/* ---------------------------------------- Heading area with general information -------------------------------------------------------- */}
            <Row className='heading_block'>
                <h2>APPLICATION</h2>
            </Row>
            
            {/* eslint-disable-next-line */}
            {(submitted == 'true') ?  
            <Row className='bg_image boxes justify-content-center'>
                <Row className='justify-content-center'>
                    <Col className='grey_text_boxes'>
                        <h3 style={{color: 'green'}}>
                            Your application has been submitted!<br/>We will get back to you soon.<br/>Thank you for applying!
                        </h3>
                    </Col>
                </Row>
            </Row> : 
            <>
            <Row className='px-5 pt-4 text-center justify-content-center'>
                <Col style={{maxWidth:'850px'}}>
                {/* <p>Already submitted a basic application? <Login variant={'link'}/></p> */}
                    <p>Employees and applicants for employment shall be afforded equal
                        opportunity in all aspects of employment without regard to race, color,
                        religion, political affiliation, national origin, disability, marital status,
                        gender or age.</p>
                    <p>As a means of accommodation to persons with specific disabilities
                        that prevent them from completing this application, confidential
                        assistance in filling out this application may be obtained by
                        contacting SICORP.</p>
                    <p>*You may answer "N/A" for questions which do not apply to the positions you are seeking.</p>
                </Col>
            </Row>
            <Row className='justify-content-center py-3 m-2'>
                <Col style={{maxWidth:'850px'}}>
                    <Form.Label>Are you a US Citizen?&emsp;
                        <Form.Check required inline type='radio' label='Yes' name='citizen' value='Yes' onChange={handleChange}/>
                        <Form.Check inline type='radio' label='No' name='citizen' value='No' onChange={handleChange}/>
                    </Form.Label>

                    <br/>

                    <Form.Label>Do you consent to participating in periodic drug screenings before and during employment?&emsp;
                        <Form.Check required inline type='radio' label='Yes' name='drug_screen' value='Yes' onChange={handleChange}/>
                        <Form.Check inline type='radio' label='No' name='drug_screen' value='No' onChange={handleChange}/>
                    </Form.Label>
                </Col>
            </Row>

            <Row className='justify-content-center py-3 m-2'>

            {/* eslint-disable-next-line */}
            {(formData.citizen == 'Yes' && formData.drug_screen == 'Yes') ? 
            <Form className='p-3 simple_app' validated={validated} onSubmit={handleSubmit}>
                
{/* ---------------------------------------- SECTION 1: Intro Info -------------------------------------------------------- */}
                
                <ol>
                    <Form.Group as={Row} >
                        <Col md='auto'><li><Form.Label htmlFor='position' column>Position Applied For</Form.Label></li></Col>
                        <Col className='form_input'>
                            <Form.Select id='position' required value={formData.position} name='position' onChange={handleChange}>
                            {/* eslint-disable-next-line */}
                                {offerings.map((job) => (
                                    <option value={job.fieldData.PostingID} key={job.fieldData.PostingID}>{job.fieldData.PostingID}</option>
                                ))}
                                <option value='Other'>Other</option>
                            </Form.Select>
                        </Col>
                        <Col md='auto'><Form.Text>(One per application)</Form.Text></Col>
                    </Form.Group>
                    {/* eslint-disable-next-line */}
                    {(formData.position == 'Other') ? 
                        <Form.Group as={Row} className='mb-3'>
                            <Col md='auto'>
                                <Form.Label htmlFor='specify' column>Specify:</Form.Label>
                            </Col>
                            <Col className='form_input'>
                                <Form.Control id='specify' required type='text' placeholder='Specify...' name='pos_specify' value={formData.pos_specify} onChange={handleChange}/>
                            </Col>
                        </Form.Group> : null
                    }
                    <Form.Group className='my-3 form_group' as={Row}>
                        <Col md='auto'><li><Form.Label htmlFor='name' column>Full Legal Name</Form.Label></li></Col>
                        <Col className='form_input'>
                            <Form.Control id='name' autoComplete='family-name' type='text' placeholder='Last' name='lname' value={formData.lname} onChange={handleChange} required/>
                        </Col>
                        <Col className='form_input'>
                            <Form.Control autoComplete='given-name' type='text' placeholder='First' name='fname' value={formData.fname} onChange={handleChange} required/>
                        </Col>
                        <Col className='form_input'>
                            <Form.Control autoComplete='additional-name' type='text' placeholder='Middle Initial' name='mi' value={formData.mi} onChange={handleChange} required/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col md='auto'><li><Form.Label htmlFor='email' column>Email Address</Form.Label></li></Col>
                        <Col className='form_input'><Form.Control id='email' autoComplete='email' required type='email' name='email' value={formData.email} onChange={handleChange} isValid={emailRegex.test(formData.email)}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid email format.
                            </Form.Control.Feedback>
                        </Col>
                        <div id='email_error' style={{color:'red'}}/>
                    </Form.Group>

{/* ---------------------------------------- SECTION 2: Resume -------------------------------------------------------- */}
                    
                    <Row className='my-3'>
                        <Col className='form_input'>
                            <Form.Label htmlFor='resume'><li><b>Upload a Resume</b></li></Form.Label>
                            <Form.Control id='resume' type='file' accept='application/pdf' name='upload' onChange={handleFileChange}/>
                            <Form.Text>*File must be in pdf format</Form.Text>
                            <div id='resume_err' style={{color:'red'}}></div>
                        </Col>
                    </Row>
                    


{/* ---------------------------------------- SECTION 3: Signature -------------------------------------------------------- */}
                    <li className='nospace'><b>CERTIFICATION</b><i>--Each Application Requires Current Date and Original Signature</i></li>
                    {signed ? <Button variant='success' disabled>Signed</Button> : <Button variant='info' onClick={handleShow}>Sign</Button>}
            
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header>
                        <Modal.Title>Electronic Signature</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                I hereby certify that all entries on this form and all attachments are true and complete, and I agree and understand that any falsification of information herein, regardless of
                                time of discovery, may cause forfeiture on my part of any employment at SICORP, Inc. I understand that all information on this application is subject to verification and I
                                consent to criminal history background checks. I also consent that you may contact references, former employers and educational institutions listed regarding this
                                application. I further authorize SICORP, Inc. to rely upon and use, as it sees fit, any information received from such contacts.
                            </p>
                            <Form.Group className='mb-3'>
                                <Form.Label htmlFor='date'>Date</Form.Label>
                                <Form.Control id='date' required type='text' placeholder='mm/dd/yyyy' name='signed_date' value={formData.signed_date} onChange={handleChange} autoFocus/>
                                <div id='date_error' style={{color:'red'}}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor='signature'>Signature</Form.Label>
                                <Form.Control id='signature' required type='text' placeholder='Full Name' name='signature' value={formData.signature} onChange={handleChange}/>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                        <div id='sign_error' style={{color:'red'}}/>
                        <Button type='submit' variant="info" onClick={check_signed}>
                            Sign
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </ol>
                <div id='sign' style={{color:'red'}}/>
                <Button variant='info' type='submit'>Submit</Button>

                {/* Visual loading for slow components */}
                {loading ? 
                <Row className='justify-content-center'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row> : null}
                
                <div id='overall_err' style={{color:'red'}}/>
                <div id='send_err' style={{color:'red'}}/>
            </Form>  : null}
            </Row>
            </>
            }           
        </div>
    );
};

export default BasicApp;