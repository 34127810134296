import React from 'react';
import {Button, Col, Row, Container} from 'react-bootstrap';

// Footer - should have copyright

function Footer({links}) {
    return(
        <div className="footer">
        <Container>
            <Row>
                <Col>
                    {links.map((link) => (
                        <Row key={link.name} className='mb-2'>
                            <a className='footer_links' rel='nofollow' href={link.ref} target={link?.target}>{link.name}</a>
                        </Row>
                    ))}
                </Col>
                <Col className='text-center'>
                    <p>Copyright © 2024 SICORP. All Rights Reserved.</p>
                </Col>
                <Col>
                    <Button href="#top" variant='secondary' style={{float: 'right'}}>
                        ^
                    </Button>
                </Col>
            </Row>
            </Container>
        </div>
    );
};

export default Footer;