import React, {useEffect, useState} from "react";
import {Row, Col, Button, Spinner} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import NotFoundPage from "./404.js";

function Job (){
    const {id} = useParams(); // gets id number from url

    // eslint-disable-next-line
    const [job, setJob] = useState((!window.localStorage.getItem('offerings')) ? '' : JSON.parse(window.localStorage.getItem('offerings'))[id-1]); // sets job to sopecific element of offerings data
    
    // variables to use during structured data setup
    var structure ={};
    var sal = '';
    var date ='';

    // used for visual loading
    const [loading, setLoading] = useState(false);
    
    // on first page load, sets the job offerings if there are none
    useEffect(() => {
        // eslint-disable-next-line
        if(!window.localStorage.getItem('offerings')){
            get_jobs();
        }
        // eslint-disable-next-line
    }, []);

    const get_jobs = async() => {
        // start visual loading
        setLoading(true);
        try {
            const response = await fetch('/api/get_jobs');
            if (!response.ok) {
                // error handling
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            else{
                // if everything goes well, sets the job data
                const data = await response.json();
                if(data[id-1]){
                    // if the job exists
                    setJob(data[id-1]);
                }
                else{
                    // if the job does not exist, set to empty
                    setJob('');
                }
                setLoading(false);

                // sets local storage so that other pages can access
                window.localStorage.setItem("offerings", JSON.stringify(data));
            }
        } catch (error) {
            // stop loading
            console.error(error);
            setLoading(false);
        }
    }

    // checks if salary or hourly fields are set (used for specific structured data for google)
    if(job && job?.fieldData?.SalaryMin){
        sal = 'YEAR'
    }
    else{
        sal = "HOUR"
    }

    // sets date formatting matching YYYY-MM-DD
    if(job && job?.fieldData?.expire_date){
        const mm = job?.fieldData?.expire_date.slice(0,2);
        const dd = job?.fieldData?.expire_date.slice(3,5);
        const yyyy = job?.fieldData?.expire_date.slice(-4);
        date = yyyy + '-' + mm + '-' + dd;
    }
    
    // defines structured data following google guidelines
    // eslint-disable-next-line
    if(job && job?.fieldData?.RemoteWork == 'Remote'){
        // if the job is remote, add the telecommute data
        structure = {
            "@context" : "https://www.schema.org/", // required
            "@type" : "JobPosting", // required
            "title" : job?.fieldData?.Title, // required
            "datePosted" : job?.fieldData?.datePosted, // required
            "description": `<p>${job?.fieldData?.Description}</p>`, // required
            "validThrough" : date,
            "hiringOrganization" : { // required
                "@type" : "Organization",
                "name" : "SICORP, Inc",
                "sameAs" : "https://www.sicorp.com",
                "logo" : "https://www.sicorp.jobs/media/sicorp_logo.png"
            },
            "applicantLocationRequirements": {
                "@type": "Country",
                "name": "USA"
            },
            "jobLocationType": "TELECOMMUTE",
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "minValue": job?.fieldData?.SalaryMin || job?.fieldData?.HourlyMin,
                    "maxValue": job?.fieldData?.SalaryMax || job?.fieldData?.HourlyMax,
                    "unitText": sal
                }
            },
            "employmentType": (job?.fieldData?.Type.replace('-','_')).toUpperCase() //changes data formatting from filemaker
        }
    }
    else if (job && job?.fieldData?.RemoteWork != 'Remote'){
        // if it is not remote, sets address data
        structure = {
            "@context" : "https://www.schema.org/", // required
            "@type" : "JobPosting", // required
            "title" : job?.fieldData?.Title, // required
            "datePosted" : job?.fieldData?.datePosted, // required
            "description": `<p>${job?.fieldData?.Description}</p>`, // required
            "validThrough" : date,
            "hiringOrganization" : { // required
                "@type" : "Organization",
                "name" : "SICORP, Inc",
                "sameAs" : "https://www.sicorp.com",
                "logo" : "https://www.sicorp.jobs/media/sicorp_logo.png"
            },
            "jobLocation": { // required
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": job?.fieldData?.streetAddress,
                    "addressLocality": job?.fieldData?.PositionCity,
                    "addressRegion": "NM",
                    "postalCode": job?.fieldData?.zipCode,
                    "addressCountry": "US"
                }
            },
            "applicantLocationRequirements": {
                "@type": "Country",
                "name": "USA"
            },
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "minValue": job?.fieldData?.SalaryMin || job?.fieldData?.HourlyMin,
                    "maxValue": job?.fieldData?.SalaryMax || job?.fieldData?.HourlyMax,
                    "unitText": sal
                }
            },
            "employmentType": (job?.fieldData?.Type.replace('-','_').toUpperCase())
        }
    }

    return(
        <>
            {/* Used for visual loading when the call takes too long */}
            {loading ? 
            <Row className='justify-content-center'>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row> : null}

            {/* Checks if the job is defined, if not, show 404 page */}
            {/* eslint-disable-next-line */}
            {(!job || job =='') ? <NotFoundPage/> :
            <>
            {/* Include structured data in script element */}
            <script type='application/ld+json'>
                {JSON.stringify(structure)}
            </script>

            {/* Buttons for navigating to each individual job posting */}
            <Row className='justify-content-center text-center mb-3'>
                {(id-1 > 0) ?
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)-1}`}>{'<'} Previous Posting</Button>
                </Col>
                : null}
                {(id < JSON.parse(window.localStorage.getItem('offerings')).length) ?
                <Col style={{maxWidth:'200px'}}>
                    <Button variant="link" href={`/${Number(id)+1}`}>Next Posting {'>'}</Button>
                </Col>
                : null}
            </Row>
            
            {/* Individual job posting visual */}
            <Row className="px-5 pb-5" key={job?.fieldData?.PostingID}>
                <h3>{job?.fieldData?.PostingID}
                <Button className='ms-5' variant='info' size='lg' href='/apply'>
                    Apply Here!
                </Button></h3>
                {(job?.fieldData?.Description) ? 
                    <><b>Job Description:</b>
                    {/* Makes sure to keep formatting of text field */}
                    <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Description.replace(/\r?\n|\r/g,'\n')}</p></> : null}
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Employment Type</p>
                        <p>{job?.fieldData?.Type}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Wage Type</p>
                        <p>{job?.fieldData?.WageType}</p>
                    </Col>
                </Row>
                {/* Only show the salary or hourly field depending on what is defined */}
                {/* eslint-disable-next-line */}
                {job?.fieldData?.WageType == 'Salary' ?
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Minimum Salary</p>
                        <p>{job?.fieldData?.SalaryMin}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Maximum Salary</p>
                        <p>{job?.fieldData?.SalaryMax}</p>
                    </Col>
                </Row> : 
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Hourly Minimum</p>
                        <p>{job?.fieldData?.HourlyMin}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Hourly Maximum</p>
                        <p>{job?.fieldData?.HourlyMax}</p>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Location</p>
                        <p>{job?.fieldData?.RemoteWork}</p>
                    </Col>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Clearance Type</p>
                        <p>{job?.fieldData?.ClearanceType}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='nospace' style={{fontWeight:'bold'}}>Position City</p>
                        <p>{job?.fieldData?.PositionCity}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(job?.fieldData?.Details) ? 
                            <><p className='nospace' style={{fontWeight:'bold'}}>Job Details</p>
                            {/* Makes sure to keep formatting of text field */}
                            <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Details.replace(/\r?\n|\r/g,'\n')}</p></> :null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><b>Expiration Date: {job?.fieldData?.expire_date}</b></p>
                    </Col>
                </Row>
            </Row>
            </>}
        </>
    )
}

export default Job;