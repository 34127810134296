import React, { useState, useEffect } from 'react';
import {Row, Col, Button, Tab, Tabs, Nav, Spinner} from 'react-bootstrap';

// Employment Opportunities Page

function Employment() {
    const w = window.innerWidth;

    // used for lazy loading background images
    useEffect(() =>{
        // check if the api is supported by the browser
        if ('IntersectionObserver' in window) {
            // set options for the IntersectionObserver
            let options = {
                rootMargin: "150px", // area around the object the detect for loading
            };

            // get all elements with className .benefits
            const images = document.querySelectorAll('.benefits');
    
            // create new Intersection observer with the abive options and entries
            let observer = new IntersectionObserver(function (entries, self) {
                entries.forEach(entry => {
                    // check if the object is in the viewport
                    if (entry.isIntersecting) 
                      { 
                        // add the background image using the dataset in the element
                        entry.target.style.backgroundImage = "url('"+entry.target.dataset.bgimage+"')";
                        self.unobserve(entry.target) // stop watching the event
                      }
                });
            }, options);

            // add an observer to each element
            images.forEach(image => { observer.observe(image) });
    
        } else {
            // load all background images automatically if the browser does not support
            const headers = document.querySelectorAll('.benefits');

            // add background image
            headers.forEach(header => {
                header.style.backgroundImage = "url('"+header.dataset.bgimage+"')";
            });
        }
    })

    const initial_val = (!window.localStorage.getItem('offerings')) ? [] : JSON.parse(window.localStorage.getItem('offerings')); // sets offerings to previous value if there is one
    const [offerings, setOfferings] = useState(initial_val);

    // used for loading and button handling
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState();

    // call to backend to get job information
    const get_jobs = async() => {
        // start loading visual
        setLoading(true);
        setDisabled(true);
        setTimeout(() => setDisabled(false), 10000);
        try {
            const response = await fetch('/api/get_jobs');
            if (!response.ok) {
                setOfferings([]);
                // error handling
                const errorDiv = document.getElementById("offerings_err");
                errorDiv.innerHTML = "There was a problem fetching job offerings. Please try again later";
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            else{
                // if everything goes well, sets the job data
                const data = await response.json();
                setOfferings(data);
                setLoading(false);
                // eslint-disable-next-line
                if(data == ''){
                    // if no jobs are sent, then give warning
                    const errorDiv = document.getElementById("offerings_err");
                    errorDiv.innerHTML = "There are currently no offerings posted. Please try again later";
                    setDisabled(true); // disable backend call button
                }
                else{
                    window.localStorage.setItem("offerings", JSON.stringify(offerings));
                }
            }
        } catch (error) {
            // disables backend call button
            setDisabled(true);
            // error handling
            const errorDiv = document.getElementById("offerings_err");
            errorDiv.innerHTML = "There was a problem fetching job offerings. Please try again later";
            // console.error('Fetch error:', error) // for testing
            setLoading(false);
        }
    }
      
    // on first page load, sets the job offerings if there are none
    useEffect(() => {
        // eslint-disable-next-line
        if(offerings == ''){
            get_jobs();
        }
        // eslint-disable-next-line
    }, []);

    // sets job offerings local storage when offerings change
    useEffect(() => {
        window.localStorage.setItem('offerings', JSON.stringify(offerings));
    }, [offerings]);


    // changing layout depending on screen size
    function screen(){
        if(w<985){
            // small screens have tabs
            return(
                <Tabs
                    defaultActiveKey="0"
                    className="mb-3"
                    fill
                >
                    {offerings.map((job, index) => (
                        // eslint-disable-next-line
                        <Tab eventKey={index} key={job?.fieldData?.PostingID} title={job?.fieldData?.PostingID} className='mx-5'>
                            <h3>{job?.fieldData?.PostingID}
                            <Button className='ms-5' variant='info' size='lg' href='/apply'>
                                Apply Here!
                            </Button>
                            </h3>
                            {(job?.fieldData?.Description) ? 
                                <><b>Job Description:</b>
                                {/* Makes sure to keep formatting of text field */}
                                <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Description.replace(/\r?\n|\r/g,'\n')}</p></> : null}
                            <Row>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Employment Type</p>
                                    <p>{job?.fieldData?.Type}</p>
                                </Col>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Wage Type</p>
                                    <p>{job?.fieldData?.WageType}</p>
                                </Col>
                            </Row>
                            {/* Only show the salary or hourly field depending on what is defined */}
                            {/* eslint-disable-next-line */}
                            {job?.fieldData?.WageType == 'Salary' ?
                            <Row>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Minimum Salary</p>
                                    <p>{job?.fieldData?.SalaryMin}</p>
                                </Col>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Maximum Salary</p>
                                    <p>{job?.fieldData?.SalaryMax}</p>
                                </Col>
                            </Row> :
                            <Row>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Hourly Minimum</p>
                                    <p>{job?.fieldData?.HourlyMin}</p>
                                </Col>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Hourly Maximum</p>
                                    <p>{job?.fieldData?.HourlyMax}</p>
                                </Col>
                            </Row>}
                            <Row>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Location</p>
                                    <p>{job?.fieldData?.RemoteWork}</p>
                                </Col>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Clearance Type</p>
                                    <p>{job?.fieldData?.ClearanceType}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className='nospace' style={{fontWeight:'bold'}}>Position City</p>
                                    <p>{job?.fieldData?.PositionCity}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(job?.fieldData?.Details) ? 
                                        <><p className='nospace' style={{fontWeight:'bold'}}>Job Details</p>
                                        {/* Makes sure to keep formatting of text field */}
                                        <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Details.replace(/\r?\n|\r/g,'\n')}</p></> :null}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Expiration Date: {job?.fieldData?.expire_date}</b></p>
                                </Col>
                            </Row>
                            {/* Links to individual job posting page */}
                            <Row>
                                <Col className='mb-2'>
                                    <Button size='sm' variant='info' href={`/${index+1}`}>View full offering</Button>
                                </Col>
                            </Row>
                        </Tab>
                    ))}
                </Tabs>
            );
        }
        else{
            // larger screens have special formatting
            return(
            
            <Tab.Container defaultActiveKey="0">
                    <Row className='mx-5 mt-5 justify-content-center'>
                            {/* Left side tabs for basic job info */}
                            <Col className='me-3 employ_container' style={{width:'25% !important'}}>
                                <Nav className="flex-column">
                                    {offerings.map((job, index) => (
                                        // eslint-disable-next-line
                                        <Nav.Item className='employ_tabs' key={job?.fieldData?.PostingID}>
                                            <Nav.Link eventKey={index}>
                                                <p><b>{job?.fieldData?.PostingID}</b></p>
                                                <p className='nospace'>{job?.fieldData?.Type}</p>
                                                <p>{job?.fieldData?.WageType}</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>

                            {/* Right hand detailed job info */}
                            <Col className='employ_col'>
                            <Tab.Content>
                                {offerings.map((job, index) => (
                                    // eslint-disable-next-line
                                    <Tab.Pane eventKey={index} className='employ_content' key={job?.fieldData?.PostingID}>
                                        <h3>{job?.fieldData?.PostingID}
                                        <Button className='ms-5' variant='info' size='lg' href='/apply'>
                                            Apply Here!
                                        </Button></h3>
                                        {(job?.fieldData?.Description) ? 
                                            <><b>Job Description:</b>
                                            {/* Makes sure to keep formatting of text field */}
                                            <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Description.replace(/\r?\n|\r/g,'\n')}</p></> : null}
                                        <Row>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Employment Type</p>
                                                <p>{job?.fieldData?.Type}</p>
                                            </Col>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Wage Type</p>
                                                <p>{job?.fieldData?.WageType}</p>
                                            </Col>
                                        </Row>
                                        {/* Only show the salary or hourly field depending on what is defined */}
                                        {/* eslint-disable-next-line */}
                                        {job?.fieldData?.WageType == 'Salary' ?
                                        <Row>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Minimum Salary</p>
                                                <p>{job?.fieldData?.SalaryMin}</p>
                                            </Col>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Maximum Salary</p>
                                                <p>{job?.fieldData?.SalaryMax}</p>
                                            </Col>
                                        </Row> : 
                                        <Row>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Hourly Minimum</p>
                                                <p>{job?.fieldData?.HourlyMin}</p>
                                            </Col>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Hourly Maximum</p>
                                                <p>{job?.fieldData?.HourlyMax}</p>
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Location</p>
                                                <p>{job?.fieldData?.RemoteWork}</p>
                                            </Col>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Clearance Type</p>
                                                <p>{job?.fieldData?.ClearanceType}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='nospace' style={{fontWeight:'bold'}}>Position City</p>
                                                <p>{job?.fieldData?.PositionCity}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {(job?.fieldData?.Details) ? 
                                                    <><p className='nospace' style={{fontWeight:'bold'}}>Job Details</p>
                                                    {/* Makes sure to keep formatting of text field */}
                                                    <p style={{whiteSpace:'pre-wrap'}}>{job?.fieldData?.Details.replace(/\r?\n|\r/g,'\n')}</p></> :null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p><b>Expiration Date: {job?.fieldData?.expire_date}</b></p>
                                            </Col>
                                        </Row>
                                        {/* Links to individual job posting page */}
                                        <Row>
                                            <Col>
                                                <Button size='sm' variant='info' href={`/${index+1}`}>View full offering</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                            </Col>
                    </Row>
                </Tab.Container>
            )
        }
    }

    return (
        <>
            {/* Section I - Heading */}
            <Row className='heading_block'>
                <h2>ARE YOU UP FOR A CHALLENGE?</h2>
            </Row>
            <Row className='bg_image boxes justify-content-center'>
                <Row className='justify-content-center'>
                    <Col className='grey_text_boxes'>
                        <p>
                        <b>Work with the most advanced technology in the world!</b><br/>
                        SICORP is always seeking qualified candidates who are interested in joining our team and wish to participate in some of the nation&apos;s most important and challenging computing efforts.<br/>
                        We are often looking to fill positions in many areas where you can apply your expertise: Sales, Consulting, Programming, Engineering, Design, Technical Support, Systems Integration, and Project Management.
                        </p>
                    </Col>
                </Row>
            </Row>

            {/* Section II - Employment Opportunities */}
            <Row className='heading_block'>
                <h2>CURRENT EMPLOYMENT OPPORTUNITIES</h2>
            </Row>
            <Row className="justify-content-center">
                <Col className='text-center my-3' md={6} xs={10}>
                    <p>
                        All candidates must be able to follow and adhere to established Safety and Security Plans.
                        Most positions require an active L or Secret Security Clearance (Q or Top Secret preferred).
                        For all positions, Military or DOE experience is a plus.
                        SICORP is an Equal Opportunity Employer/Disabled/VETS/Affirmative Action Employer.
                    </p>
                    <p><b>All applicants must be US citizens.</b></p>
                    <p><b>All employees will be required to go through periodic drug screenings.</b></p>
                </Col>
            </Row>

            <Row className='justify-content-center mb-3'>
                <Col md='auto' xs='auto'>
                    <Button variant='info' disabled={disabled} onClick={get_jobs}>Check for new offerings</Button>
                </Col>
            </Row>

            {/* Used for visual loading when the call takes too long */}
            {loading ? 
            <Row className='justify-content-center'>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row> : null}

            <div className='text-center' id='offerings_err' style={{color:'red'}}></div>
            {screen()}

            {/* Apply Here button */}
            <Row>
                <Col className='text-center mb-3'>
                    <Button variant='info' size='lg' href='/apply'>
                        Apply Here!
                    </Button>
                </Col>
            </Row>

            {/* Section III - Benefits */}
            <Row className='heading_block'/>
            <Row className='benefits bg_image p-4 justify-content-center' data-bgimage="/media/SLIDE_Alpha_019.jpg">
                <Row className='justify-content-center'>
                    <Col className='grey_text_boxes'>
                        <h3 style={{marginBottom:'20px'}}>SICORP offers competitive <br/>employee benefits:</h3>
                        <ul style={{textAlign:"left"}}>
                            <li>Group Medical Insurance</li>
                            <li>Dental Insurance</li>
                            <li>Vision Insurance</li>
                            <li>Company-Paid Life Insurance</li>
                            <li>Ten Paid Holidays Per Year</li>
                            <li>Paid Annual Leave</li>
                            <li>401K Plan with Company Match</li>
                            <li>Training Reimbursement</li>
                            <li>Direct Payroll Deposit</li>
                            <li>Spot Bonus Plan</li>

                        </ul>
                    </Col>
                </Row>
            </Row>
            
        </>
    );
};

export default Employment;