// importing all pages and stylesheet

import './App.scss';
import{Routes, Route, BrowserRouter as Router} from "react-router-dom";
import Employment from './employment.js';
import BasicApp from './basic_application.js';
import Header from './header.js';
import Footer from './footer.js';
import NotFoundPage from './404.js';
import Job from './job.js';

// loads all pages to the App
//testing

function App() {
  // Defines the links in the header and where they go
  const header_links = [
      {ref:'/', name:'Job Postings'},
  ];

  // Defines the links in the footer and where they go
  const footer_links = [
      {ref:'https://sicorp.com/About.html', name:'About'},
      {ref:'https://sicorp.com/Solutions.html', name:'Solutions'},
      {ref:'https://sicorp.atlassian.net/wiki/spaces/CR/overview?mode=global', name:'Support', target:'_blank'},
      {ref:'https://sicorp.com/Government_Contracting.html', name:'Government Contracting'},
      {ref:'/', name:'Employment Opportunities'},
      {ref:'https://sicorp.statuspage.io/', name:'Status Page', target:'_blank'},
  ];

  return (
    <>
      {/* Defines all paths to each page */}
      <Router>
          <Header logo='/media/sicorp_logo.png' alt='SICORP Logo' links={header_links}/>
            <Routes>
              <Route path='' element={<Employment />}/>
              <Route path='/apply' element={<BasicApp/>}/>
              <Route path='*' element={<NotFoundPage/>}/>

              {/* Used for individual job posting pages to get id */}
              <Route path="/:id" element={<Job />} />
            </Routes>
          <Footer links={footer_links}/>
      </Router>
      
    </>
  );
}

export default App;
