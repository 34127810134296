import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';
// import { useAuth } from './hooks/AuthProvider';

// Header for all pages

function Header({logo, alt, links}) {
  // const auth = useAuth();
  return (
    <>

      {/* Logo */}
      <Row className="justify-content-center m-3" id='top'>
        <Col md='auto' xs='auto'><a href='https://www.sicorp.com' rel='noreferrer' target='_blank'><Image fluid loading='lazy' src={logo} alt={alt} className='header_logo' width='288px' height='42.84px'/></a></Col>
      </Row>
      {/* {auth?.user == '' ? 
        <Col className='login_button p-4'>
          <Button variant='info' href='/login'>Login</Button>
        </Col> :
        <Col className='account_info p-4'>
          <a href='/account'>Logged in as: {auth.user}</a>
        </Col>
      } */}

      {/* Header Links */}
      <Row className='head p-3 text-center justify-content-center'>
        {links.map((link) => (
            <Col key={link.name}>
                <a href={link.ref} className='header_links'>{link.name}</a>
            </Col>
        ))}
      </Row>
    </>
  );
};

export default Header;